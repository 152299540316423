@import '../../assets/scss/_variables';
@import '../../assets/scss/_media-queries';

.complaint-list {
  .search-form {
    .input, .select {
      padding-bottom: 15px;
    }
    &__btn {
      margin-bottom: 10px;
      &:last-child {
        margin-left: 10px;
      }
      @include max-screen($breakpoint-lg) {
        margin-bottom: 15px;
      }
    }
  }
}