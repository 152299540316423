.form-contact-us {
  form {
    width: 75%;
    margin: auto;
  }

  .form-control {
    font-size: 1rem !important;
  }

  .form-group {
    margin-bottom: 1.3rem;
  }

  button[type='submit'] {
    width: 25%;
  }
}
