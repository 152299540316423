@import '../../assets/scss/_variables';

.report{
  .page-header {
    display: flex;
    justify-content: space-between;
  }
  &__content {
    width: 100%;
    border-radius: .625rem;
    box-shadow: $shadow;
    margin-bottom: 30px;
    padding: 30px;
  }
}