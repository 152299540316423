
body{
  .btn {
    font-size: $btn-fz;
    line-height: $btn-lh;
    &-danger {
      background-color: $danger-color
    }
    &-primary {
      background-color: $primary-color
    }
    &-success {
      background-color: $success-color
    }
    &-warning {
      background-color: $warning-color
    }
  }
}