.form-group {
  label {
    margin-bottom: 0.6rem;
    font-size: 15px;
  }
  .form-control {
    font-size: $input-fz;
    line-height: $input-lh;

  }
  .col-form-label {
    line-height: $label-lh;
  }
  .input {
    &_focus {
      .form-control {
        border-color: $primary-color;
        box-shadow:  0 5px 11.5px rgba(0, 123, 255, 0.1);
      }
    }
    &_value {
      .form-control {
        border-color: $success-color;
        box-shadow:  0 5px 11.5px rgba(23,198,113,.1);
      } 
    }
    &_error {
      .form-control {
        border-color: $danger-color;
        box-shadow: 0 5px 11.5px rgba(196,24,60,.1);
      }
    }
  }
}
form {
  .form-control, .DayPickerInput>input {
    font-size: $input-fz;
    line-height: $input-lh;
    &:focus {
      border-color: $primary-color;
      box-shadow:  0 5px 11.5px rgba(0, 123, 255, 0.1);
    }
  }
  .col-form-label {
    line-height: $label-lh;
  }
}

.checkbox {
  input[type="checkbox"]:checked, 
  input[type="checkbox"]:not(:checked) 
  {
    position: absolute;
    left: -9999px;
  }

  input[type="checkbox"]:checked + label, 
  input[type="checkbox"]:not(:checked) + label {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    line-height: 20px;
    cursor: pointer;
  }
  input[type="checkbox"]:checked + label:before, 
  input[type="checkbox"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 20px;
    height: 20px;
    border: 1px solid #dddddd;
  }

  input[type="checkbox"]:checked + label:before, 
  input[type="checkbox"]:not(:checked) + label:before {
    border-radius: 2px;
  }
  input[type="checkbox"]:checked + label:before {
    background-color: $primary-color;
  }
  input[type="checkbox"]:not(:checked) + label:before {
    background-color: white;
  }

  input[type="checkbox"]:checked + label:after, 
  input[type="checkbox"]:not(:checked) + label:after {
    content: "";
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  input[type="checkbox"]:checked + label:after, 
  input[type="checkbox"]:not(:checked) + label:after {
    left: 5px;
    top: 6px;
    width: 10px;
    height: 6px;
    border-radius: 1px;
    border-left: 3px solid white;
    border-bottom: 3px solid white;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
  }

  input[type="checkbox"]:checked + label:after{
    opacity: 1;
  }
}
.radio {
  
  input[type="radio"]:checked + label:before, 
  input[type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      width: 20px;
      height: 20px;
      border: 1px solid #dddddd;
      background-color: #ffffff;
      border-radius: 100%;
      transition:  0.3s ease;
  }
  input[type="radio"]:checked + label:before {
    background-color: $primary-color;


  }
  input[type="radio"]:checked, 
  input[type="radio"]:not(:checked) 
  {
      position: absolute;
      left: -9999px;
  }
  input[type="radio"]:checked + label, 
  input[type="radio"]:not(:checked) + label {
      display: inline-block;
      position: relative;
      padding-left: 28px;
      line-height: 20px;
      cursor: pointer;
  }
  input[type="radio"]:checked + label:after {
    animation: growcheck 0.1s ease-in;
    background-color: #ffffff;
    
  }
  input[type="radio"]:not(:checked) + label:after {
    background-color: $primary-color;
  }
  input[type="radio"]:checked + label:after, 
  input[type="radio"]:not(:checked) + label:after {
      content: "";
      position: absolute;
      // -webkit-transition: all 0.2s ease;
      // -moz-transition: all 0.2s ease;
      // -o-transition: all 0.2s ease;
      // transition:  0.3s ease;
  }
  input[type="radio"]:checked + label:after, 
  input[type="radio"]:not(:checked) + label:after {
      left: 6px;
      top: 6px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: white;
  }
  input[type="radio"]:not(:checked) + label:after {
      opacity: 0;
  }

  input[type="radio"]:checked + label:after {
      opacity: 1;
  }
}

@keyframes growcheck {
  from {
    left: 9px;
    top: 9px;
    width: 1px;
    height: 1px;
  }

  to {
    left: 6px;
    top: 6px;
    width: 8px;
    height: 8px;
  }
}