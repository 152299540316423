@import '../../assets/scss/variables';

.navigation {
  &.navbar{
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
  position: relative;
  margin-right: -15px;
  margin-left: -15px;
  background-color: $nav-color;
  box-shadow: 1px 0 20px 0 $nav-color;
  // box-shadow: 0 0.125rem 9.375rem rgba(90,97,105,.1), 0 0.25rem 0.5rem rgba(90,97,105,.12), 0 0.9375rem 1.375rem rgba(90,97,105,.1), 0 0.4375rem 2.1875rem rgba(165,182,201,.1);
  #notification {
    .active {
      fill: $warning-color;
    }
    cursor: pointer;
    width: 20px;
    height: 30px;
    fill: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    // margin-right: 15px;
    &:hover {
      fill: white;
    }
  }
  &__nav-block {
    align-items: center;
    position: relative;
  }
  .active {
    #notification {
      fill: $warning-color;
    }
  }
  .nav-link {
    display: flex;
    align-items: center;
    #user {
      cursor: pointer;
      width: 20px;
      height: 30px;
      fill: rgba(255, 255, 255, 0.5);
      font-size: 15px;
      margin-right: 10px;
    }
    &.active {
      #user {
        fill: white;
      }
    }
    &:hover {
      #user {
        fill: white;
      }
    }
  }
  .pulsating-circle {
    position: absolute;
    left: -2px;
    top: 40%;
    transform: translateX(-50%) translateY(-50%);
    width: 7px;
    height: 7px;
    
    &:before {
      content: '';
      position: relative;
      display: block;
      width: 300%;
      height: 300%;
      box-sizing: border-box;
      margin-left: -100%;
      margin-top: -100%;
      border-radius: 45px;
      background-color: $warning-color;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
    
    &:after {
      content: '';
      position: absolute;
      left: 0; 
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $warning-color;
      border-radius: 15px;
      box-shadow: 0 0 8px $warning-color;
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
    }
  }
  
  @keyframes pulse-ring {
    0% {
      transform: scale(.33);
    }
    80%, 100% {
      opacity: 0;
    }
  }
  
  @keyframes pulse-dot {
    0% {
      transform: scale(.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(.8);
    }
  }
}