.ecms-carousel {
  .carousel-caption {
    position: initial;
    color: rgb(0, 117, 193);
  }

  .carousel-indicators {
    bottom: -1rem;

    & > li {
      background-color: darkgrey;
    }
  }

  .carousel-item {
    img {
      border-radius: 0.5rem;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    top: -7rem;
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='darkgrey' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/></svg>");
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='darkgrey' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/></svg>");
  }
}
