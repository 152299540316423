.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  font-size: 1.2rem;
  text-align: center;

  .section {
    margin-top: 4rem;

    h1 {
      font-family: 'Fjalla One', arial, sans-serif;
      font-size: 2.5rem;
      color: rgb(0, 117, 193);
      text-transform: uppercase;
      margin-bottom: 3rem;

      hr {
        width: 5rem;
        border: none;
        border-bottom: 0.2rem solid silver;
        text-align: center;
        margin: 0 auto;
        margin-top: 1.5rem;
      }
    }

    .fa-layers-text {
      font-size: 0.85rem;
    }
  }

  .section-header {
    font-family: 'Fjalla One', arial, sans-serif;
    margin-top: 0;

    .title {
      font-size: 3rem;
      margin: auto;
    }
  }

  .section-description {
    .text {
      font-size: 2rem;
      padding: 0 0 4rem 0;
    }

    .button {
      a {
        font-size: 1.5rem;
        text-transform: uppercase;
      }
    }
  }

  .section-services {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;

    .fa-circle {
      font-size: 0.35rem;
      vertical-align: middle;
    }
  }

  .section-contact-us {
    text-align: center;

    button {
      font-size: 1.5rem;
      text-transform: uppercase;
    }
  }

  .footer {
    padding: 5rem 0 2rem 0;
    text-align: center;
    background-color: rgb(0, 117, 193);
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1rem;
  }
}
