.contact-us {
  margin-top: 2rem;

  .form-shown,
  .form-submitted {
    h1 {
      font-family: unset !important;
      font-size: 1.5rem !important;
    }
  }

  .form-submitted {
    font-size: 1.5rem;

    svg {
      height: 5rem;
      fill: green;
    }

    p {
      margin-top: 1rem;
      color: green;
    }
  }
}
