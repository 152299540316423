
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  color: #5a6169;

}

body, html {
  // font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
}

body {
  // font-family: $font-family;
  // font-size: $font-size;
  // font-weight: $regular;
  // color: $text-color;
  // min-width: $layout-min-width;
  // min-height: $tablet-height;
  // background: linear-gradient(left, $color-purple-dark, $color-purple-medium);
  .notification-container {
    top: 70px;
    .notification {
      margin: 15px;
      box-shadow: 1 1 12px #999;
    }
    .message{
      color: white;
    }
  }
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

input, textarea {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    // text-fill-color: $text-color !important;
    // -webkit-text-fill-color:  $text-color !important;
  }
}
h1,h2,h3,h4,h5,h6 {
  color: $header-color;
} 
.page-header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
table.table {
  color: $table-color;
}
.table-responsive {
  border-radius: .625rem;
  box-shadow: $shadow;
  margin-bottom: 30px;
  table {
    border-color: white;
  }
  .table {
    color: $table-color;
    margin-bottom: 0;
    th {
      &.sortable {
        &.sorted {
          &.asc {
            &:after {
              content: "";
              border: 5px solid transparent;
              border-top: 5px solid $table-color;
              position: absolute;
              right: 0;
              top: 23px;
            }
          }
          &.desc {
            &:after {
              content: "";
              border: 5px solid transparent;
              border-bottom: 5px solid $table-color;
              position: absolute;
              right: 0;
              top: 18px;
            }
          }
        }
        &:hover {
          &.asc {
            &:after {
              content: "";
              border: 5px solid transparent;
              border-top: 5px solid $table-color;
              position: absolute;
              right: 0;
              top: 23px;
            }
          }
          &.desc {
            &:after {
              content: "";
              border: 5px solid transparent;
              border-bottom: 5px solid $table-color;
              position: absolute;
              right: 0;
              top: 18px;
            }
          }
        }
        &::selection {
          background: transparent;
         }
        cursor: pointer;
        position: relative;
        
        
      }

      border-top: 0;
    }
    tr {
      td {
        vertical-align: middle;
        word-wrap: break-word;
      }
      .row-btn {
        &:last-child {
          margin-left: 15px;
        }
      }
    }
  }
}
.content-footer {
  justify-content: flex-end;
  align-items: center;
}
.btn-row {
  button {
    margin-left: 10px;
    &:last-child {
      margin-left: 0;
    }
  }
}
select.form-control {
  &:focus {
    border-color: $primary-color;
    box-shadow:  0 5px 11.5px rgba(0, 123, 255, 0.1);
  }
} 
body, html {
  height: 100%;
}